@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// html {
//   overflow: hidden;
// }

body {
  height: 100vh;
  overflow-y: auto;
  font-family: DM Sans;
  color: #151411;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::-webkit-scrollbar {
    background: #f3f4f8;
    -webkit-border-radius: 1ex;
    height: 9px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #09066b;
    -webkit-border-radius: 1ex;
  }

  &::-webkit-scrollbar-corner {
    background: #fff3;
  }
}

::selection {
  @apply bg-primarylight text-primary;
}
.secondaryfont {
  font-family: 'Inter', sans-serif;
}

.wrapper {
  @apply px-5 mdlage:mx-[14rem] largest:mx-[30rem] mx-0 md:px-10 lg:px-20 2xl:px-60;
}

.page-link {
  @apply px-2 md:px-3 py-2 text-sm font-medium;
}

.nav-links {
  @apply hover:border-b-2 border-primary pb-1.5 cursor-pointer;
}

.faq {
  @apply p-4 cursor-pointer;
  &.active {
    @apply md:bg-[#EFEEFD] border-b-4 md:border-b-0 md:border-l-8 border-primary font-medium;
  }
}
.pagination_active {
  @apply text-xs md:text-sm  rounded-lg cursor-pointer text-blue bg-blue  bg-opacity-5;
}
.pagination-container {
  @apply flex items-center flex-wrap md:flex-nowrap gap-2 md:gap-3;
}
.pagination-disabled {
  @apply opacity-20;
}

.status {
  @apply px-3 py-1 flex font-medium items-center justify-center text-center;
  &.active {
    @apply bg-green-200  text-green-800 rounded-lg capitalize;
  }
  &.inactive {
    @apply bg-red-200  text-red-800 rounded-lg capitalize;
  }
}

button {
  &.primary {
    @apply bg-primary text-white border border-primary;
  }
  &.outline {
    @apply text-primary bg-white border font-normal text-sm border-primary border-opacity-10;
  }
  &.purpleoutline {
    @apply text-purple2 bg-white border-2 border-purple2;
  }
  &.purple {
    @apply bg-purple2 text-white border border-purple2;
  }
  &.gray {
    @apply bg-[#151411] bg-opacity-40 text-white;
  }
}
.newtons-cradle {
  --uib-size: 50px;
  --uib-speed: 1.2s;
  --uib-color: #09066b;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--uib-size);
  height: var(--uib-size);
}

.newtons-cradle__dot {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 25%;
  transform-origin: center top;
}

.newtons-cradle__dot::after {
  content: '';
  display: block;
  width: 100%;
  height: 25%;
  border-radius: 50%;
  background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
  animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
  animation: swing2 var(--uib-speed) linear infinite;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  25% {
    transform: rotate(70deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  75% {
    transform: rotate(-70deg);
    animation-timing-function: ease-in;
  }
}

.header {
  @apply text-base sm:text-xl font-medium;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fullflex {
  @apply flex items-center justify-between;
}

.card {
  @apply bg-white px-5 py-4 rounded-lg;
}

.paginationWrapper {
  @apply flex items-center justify-center p-1.5 md:p-3 border rounded-lg cursor-pointer border-gray4 hover:opacity-70;
}

p {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.45767441391944885px;
}

.marquee {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
  --offset: -20vw;
  --move-initial: calc(-5% + var(--offset));
  --move-final: calc(8% + var(--offset));

  .marquee__inner {
    width: fit-content;
    position: relative;
    transform: translate3d(var(--move-initial), 0, 0);
    animation: marquee 5s linear infinite;
    animation-play-state: paused;
    animation-play-state: running;
    opacity: 1;
    transition-duration: 0.4s;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
